import { DEFAULT_CATEGORIES, DEFAULT_DEMOS, LENSES_PACKAGES } from '@constants';
import { BuildFlowData, RxOption } from '@ts/index';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE_HANDLE_MAP, RX_TYPE as RX_TYPE_OPTION } from './constants/base-skus';

export const parseLensOptionToLensPackage = (lensOption: BASE_FRAME_LENS_OPTIONS): LENSES_PACKAGES => {
	switch (lensOption) {
		case BASE_FRAME_LENS_OPTIONS.CR39:
			return LENSES_PACKAGES.BASIC;
		case BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS:
			return LENSES_PACKAGES.PREMIUM;
		default:
			return LENSES_PACKAGES.STANDARD;
	}
};

export const findLensPackageInLensOptions = (lensOptions: BASE_FRAME_LENS_OPTIONS[]): LENSES_PACKAGES => {
	return parseLensOptionToLensPackage(
		lensOptions.find(lens => lens === BASE_FRAME_LENS_OPTIONS.CR39 || lens === BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS)
	);
};

/**
 * Normalizes build flow data from Contentful.
 * Handles visibilty logic for different flows / demos.
 */
export const createRxOptionsForJourney = (
	buildFlowData: BuildFlowData,
	journey: (typeof DEFAULT_CATEGORIES)[number],
	demo: (typeof DEFAULT_DEMOS)[number]
) => {
	let rxOptions = buildFlowData.rxOptions;

	if (journey !== 'eyeglasses') {
		// move nonRX option to first position
		const indexOfNonRx = rxOptions.findIndex(r => r.type === RX_TYPE_OPTION.NON_RX);
		if (indexOfNonRx > 0) {
			rxOptions.unshift(rxOptions.splice(indexOfNonRx, 1)[0]);
		}

		// remove all lenses
		rxOptions = rxOptions.map(option => ({
			...option,
			lenses: [],
		}));
	}

	if (demo === 'kids') {
		// don't show premium plus
		rxOptions = rxOptions.map(option => ({
			...option,
			lenses: option.lenses.filter(lens => lens.type !== BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS),
		}));
	}

	return rxOptions.map<RxOption>(option => {
		return {
			id: RX_TYPE_HANDLE_MAP[option.type],
			copy: option.copy.title,
			description: option.copy.description,
			lenses: option.lenses.map(lens => ({
				name: lens.copy.title,
				description: lens.copy.description,
				optionValue: lens.type,
				handle: lens.handle,
				tag: lens.tag,
				...(lens.alternateCopy && { alternateCopy: lens.alternateCopy }),
			})),
			optionValue: option.type,
			...(option.alternateCopy && { alternateCopy: option.alternateCopy }),
		};
	});
};

/**
 * Updates an array of Rx Options so that each object uses data in its
 * "alternateCopy" field for displayed copy. Does the same for any nested Lens objects.
 * Use this for AB testing copy.
 */
export const createRxOptionsWithAlternateCopy = (rxOptions: RxOption[]) => {
	return rxOptions.reduce<RxOption[]>((a, rx) => {
		rx.lenses = rx.lenses.map(lens => {
			if (Object.hasOwn(lens, 'alternateCopy')) {
				return {
					...lens,
					name: lens.alternateCopy.title,
					description: lens.alternateCopy.description,
				};
			}
			return lens;
		});

		if (Object.hasOwn(rx, 'alternateCopy')) {
			a.push({
				...rx,
				copy: rx.alternateCopy.title,
				description: rx.alternateCopy.description,
			});
		}

		return a;
	}, []);
};
