/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { UseFormRegister } from 'react-hook-form';
import { useClickOutside } from '@utils/hooks';
import { AUFlag, Button, CanadaFlag, Caption, Flex, UKFlag, USFlag } from '@components';
import { ButtonProps } from '../Button';
import styles from './Select.module.scss';

export type SelectOption = { copy: string; value: string | number };
export type SelectValues = string[] | SelectOption[];

type SelectProps<T> = {
	buttonProps?: Partial<ButtonProps>;
	buttonClassName?: string;
	containerClassName?: string;
	register?: () => ReturnType<UseFormRegister<T>>;
	handler: (v: string | SelectOption) => void;
	placeholder?: string;
	prevSelection?: string;
	values: SelectValues;
	instruction?: string;
	/** If an unselectable instruction option has been provided, setting this value true will render the instruction in the middle of the value array */
	centerInstruction?: boolean;
	isListOnTop?: boolean;
	label?: string;
	withIcon?: boolean;
};

/**
 * NOTE: This is a potential replacement for the 'Select' component:
 * - allows for array of strings/numbers OR an array of objects that have different copy than their value
 * - incoporates A11y features
 * - fully customized styling including dropdown
 */
const Select = <T,>({
	buttonProps,
	buttonClassName = '',
	containerClassName = '',
	handler,
	placeholder,
	prevSelection = null,
	values,
	instruction,
	centerInstruction,
	isListOnTop = false,
	label,
	withIcon = false,
	...rest
}: SelectProps<T>) => {
	const selectWrapperRef = useRef(null);
	const [selected, setSelection] = useState(prevSelection ?? values[0]);
	const [selectIndex, setSelectIndex] = useState<null | number>(null);
	const [isOpen, setIsOpen] = useState(false);
	useClickOutside(selectWrapperRef, () => setIsOpen(false));
	const buttonSize = buttonProps?.size;

	const insertInstructionIfItExists = useCallback((v: typeof values, i: typeof instruction, ci: typeof centerInstruction) => {
		const copy: SelectValues = [...v] as SelectValues;

		if (i && ci) {
			copy.splice(copy.length / 2, 0, i);
			return copy;
		}

		if (i) copy.splice(0, 0, i);
		return copy;
	}, []);

	const valuesToMap: SelectValues = insertInstructionIfItExists(values, instruction, centerInstruction);

	const handleSelect = useCallback(
		(index, option) => {
			setSelection(option);
			setSelectIndex(index);
			setIsOpen(false);
			handler(option);
		},
		[handler]
	);

	const handleKeyDown = useCallback(
		(index, option) => e => {
			switch (e.key) {
				case ' ':
				case 'SpaceBar':
				case 'Enter':
					e.preventDefault();
					handleSelect(index, option);
					break;
				default:
					break;
			}
		},
		[handleSelect]
	);

	const selectionCopy = typeof selected === 'string' ? selected : selected?.copy;

	const getIcon = useCallback((selectionCopy: string) => {
		switch (selectionCopy) {
			case 'Canada':
			case 'CA':
				return <CanadaFlag />;
			case 'United States':
			case 'US':
				return <USFlag />;
			case 'United Kingdom':
			case 'UK':
			case 'GB':
				return <UKFlag />;
			case 'Australia':
			case 'AU':
				return <AUFlag />;
			default:
				return null;
		}
	}, []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const icon = useMemo(() => getIcon(selectionCopy), [selectionCopy]);

	return (
		<div
			className={`${styles.container} ${containerClassName && styles[containerClassName]}`}
			ref={selectWrapperRef}
			{...rest}
		>
			<Button
				color='white'
				aria-haspopup='listbox'
				aria-expanded={isOpen}
				withChevron={true}
				chevronDirection={isOpen ? 'up' : 'down'}
				onClick={() => setIsOpen(!isOpen)}
				extraClasses={buttonClassName}
				label={selectIndex === null ? placeholder : selectionCopy}
				icon={withIcon && icon}
				{...buttonProps}
			/>
			{label && <label>{label}</label>}
			<ul
				className={cn(styles.list, { [styles.listOnTop]: isListOnTop }, { [styles.show]: isOpen })}
				role='listbox'
				aria-activedescendant={selectIndex ? selectionCopy : null}
			>
				<div className={styles.fade} />
				<Flex column align='start' gap={3} className={styles.scroll}>
					{valuesToMap.map((option, index) => (
						<li
							key={`${option.copy}-${index}`}
							id={option.copy}
							role='option'
							className={cn(styles.option, {
								[styles['is-selected']]: selected === option && selectIndex,
								[styles['is-instruction']]: option === instruction,
							})}
							aria-selected={index === selectIndex}
							tabIndex={1}
							onKeyDown={handleKeyDown(index, option)}
							onClick={() => handleSelect(index, option)}
						>
							<Caption small={buttonSize === 'xsmall'}>{typeof option === 'string' ? option : option.copy}</Caption>
						</li>
					))}
				</Flex>
			</ul>
		</div>
	);
};

export default memo(Select);
